.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.about-header {
    text-align: center;
    margin-bottom: 20px;
}

.about-header h1 {
    font-size: 2em;
    margin: 0;
}

.about-header p {
    margin: 5px 0;
}

.about-header a {
    color: #f39c12;
    text-decoration: none;
}

.about-header a:hover {
    text-decoration: underline;
}

.experience, .education, .other-jobs, .skills, .hobbies {
    margin-bottom: 30px;
}

h2 {
    border-bottom: 2px solid #f39c12;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.experience-item, .education-item {
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}
.projects-content {
    display: flex;
    flex-direction: column;
    padding: 4%;
}

.intro {
    margin-bottom: 2rem;
}

.intro-text {
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 1.6;
}

.bold-text {
    font-weight: 600;
    color: #f39c12;
}

.projects-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}

.project-item {
    display: block;
    text-decoration: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s;
}

.project-item:hover {
    transform: scale(1.05);
    border: 2px solid #f39c12;
}

.project-title {
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: #f39c12;
    text-align: center;
}

.project-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.project-description {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4;
    color: #ddd;
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center; /* Center pagination items horizontally */
    margin-top: 2rem; /* Add some space above the pagination */
}

.page-item {
    cursor: pointer; /* Change cursor to pointer on hover */
    padding: 0.5rem 1rem; /* Add padding around pagination numbers */
    margin: 0 0.25rem; /* Add spacing between pagination items */
    font-size: 1rem; /* Adjust font size */
    border-radius: 0.25rem; /* Rounded corners */
    background-color: #f0f0f0; /* Light background for pagination items */
    color: #333; /* Default text color */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.page-item.active {
    background-color: #f39c12; /* Highlighted background color */
    color: #fff; /* Text color for active page */
    font-weight: bold; /* Make active page number bold */
}

.page-item:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
    .projects-content {
        padding: 1rem;
    }

    .intro-text {
        font-size: 1rem;
    }

    .project-description {
        font-size: 0.9rem;
    }
}
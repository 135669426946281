.contact-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.contact-header {
    text-align: center;
    margin-bottom: 20px;
}

.contact-header h1 {
    font-size: 2em;
    margin: 0;
}

.contact-header p {
    margin: 10px 0;
}

.contact-info, .contact-form {
    margin-bottom: 30px;
}

.contact-info h2, .contact-form h2 {
    border-bottom: 2px solid #f39c12;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.contact-info p {
    margin: 10px 0;
}

.contact-info a {
    color: #f39c12;
    text-decoration: none;
}

.contact-info a:hover {
    text-decoration: underline;
}

.contact-form form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    margin-bottom: 5px;
    font-weight: bold;
}

.contact-form input, .contact-form textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.contact-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #f39c12;
    color: white;
    font-size: 1em;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #e67e22;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #141414;
}

.left-header{
    display: flex;
    justify-content: space-between;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 2rem;
}

.social-icons {
    display: flex;
}

.social-icon {
    margin: 0 0.5rem;
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.social-icon:hover {
    color: #f39c12;
}

.menu-toggle {
    display: none;
    font-size: 24px;
    cursor: pointer;
}

nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    margin-left: 20px;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

nav ul li a:hover {
    color: #f39c12;
}


@media (max-width: 1100px) {
    .menu-toggle {
        display: block;
    }

    nav {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        background-color: #141414;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
    }

    nav.open {
        display: flex;
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 10px 0;
    }
}

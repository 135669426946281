.main-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 4% 4%;
}

.intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.text-container {
    flex: 1;
    min-width: 200px;
}

.intro-text {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
}

.intro-name {
    font-size: 4rem;
    font-weight: 700;
    margin: 0;
    line-height: 1.1;
    color: #f39c12;
    transition: color 0.3s;
}

.intro-name:hover {
    color: #e67e22;
}

.intro-title {
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.resume-btn {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    color: #fff;
    background-color: #f39c12;
    border: none;
    border-radius: 25rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.resume-btn:hover {
    background-color: #e67e22;
}


.image-container {
}

.profile-image {
    width: 100%;
    max-width: 500px;
    min-width: 200px;
    border: none;
    border-radius: 50px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }

    .intro {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .text-container {
        margin-bottom: 2rem;
    }

    .intro-name {
        font-size: 3rem;
    }

    .intro-title {
        font-size: 1rem;
    }

    .portfolio-btn {
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
    }

    .image-container {
    }

    .profile-image {
        align-content: center;
        max-width: 300px;
    }
}